<!--绩效评定-->
<template>
  <div>
    <el-form ref="searchForm"   label-width="240" :inline="true">
      <el-form-item label="选择项目:" prop="name">
        <el-select v-model="article" placeholder="请选择" style="width:240px" clearable>
          <el-option v-for="data in optionsype" :key="data.plot_id" :label="data.plot_name" :value="data.plot_id"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="报修状态:" prop="status">
        <el-select v-model="status" placeholder="请选择" style="width:240px" clearable>
          <el-option  v-for="data in statuss" :key="data.value" :label="data.label" :value="data.value"></el-option>
        </el-select>
      </el-form-item>
		<el-form-item label="报修类型:" prop="type">
        <el-select v-model="type" placeholder="请选择" style="width:240px" clearable>
          <el-option  v-for="data in typeList" :key="data.id" :label="data.name" :value="data.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="时间查询:">
        <el-date-picker
            v-model="selecttime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="YYYY-MM-DD"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="综合查询:" prop="name"><el-input  v-model="tableDataName" placeholder="请输入报修人姓名/报修ID/报修内容搜索" style="width:300px" clearable></el-input></el-form-item>
      <el-form-item><el-button type="primary" @click="handleClickQuery()" icon="el-icon-search" >搜索</el-button></el-form-item>
      <el-form-item><el-button type="danger" @click="reset()" plain>重置</el-button></el-form-item>
    </el-form>
    <el-table :data="dataList" border  ref="multipleTable"  @selection-change="handleSelectionChange">
      <el-table-column prop="id" label="ID" fixed></el-table-column>
      <el-table-column prop="user_name" label="报修用户" ></el-table-column>      //repair_username
      <el-table-column prop="real_name" label="真实姓名"  :formatter="forstusta"></el-table-column>
      <el-table-column prop="father_name" label="报修类型"></el-table-column>
      <el-table-column prop="plot_name" label="所属项目"></el-table-column>
      <el-table-column prop="mode" label="报修模式" :formatter="formode"></el-table-column>
      <el-table-column prop="content" label="报修详情"  :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="order_type" label="工单类型" :formatter="forordertype" ></el-table-column>
      <el-table-column prop="status" label="报修状态" :formatter="forstatus"></el-table-column>
      <el-table-column prop="repair_user" label="维修人员id"  v-if="false"></el-table-column>
      <el-table-column prop="repair_username" label="维修人员"  :show-overflow-tooltip="true"></el-table-column>
	  <el-table-column prop="pingEvaluationName" label="绩效评定人"  :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="优先级"  prop="priority"  :formatter="forpriority"></el-table-column>
      <el-table-column fixed="right" label="操作" width="150px" >
        <template v-slot="scope"  >
          <el-button  icon="el-icon-view" @click="View(scope.row)" type="view">
			   {{scope.row.status == '3'?'评定绩效':'查看绩效'}}
		  </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-config-provider :locale="locale">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pagesize"
          layout="total,sizes,prev,pager,next,jmper"
          :total="total">
      </el-pagination>
    </el-config-provider>

  </div>
</template>
<script>
//引入vue方法
import { ElConfigProvider } from 'element-plus';
//中文包
import zhCn from "element-plus/lib/locale/lang/zh-cn";
export default {
  name: 'ZhProvider',
  setup(){
    let locale = zhCn
    return{
      locale
    }
  },
  created() {	  
	if(this.$route.params.currentPage != null && this.$route.params.currentPage != undefined){
		this.currentPage = this.$route.params.currentPage;		
		this.article = this.$route.params.article;    //所属项目
		this.status = this.$route.params.status;      //会员状态
		this.selecttime = this.$route.params.selecttime; //查询时间
		this.tableDataName = this.$route.params.tableDataName;   //综合查询		
		this.type = this.$route.params.type;
	}	
    this.handleClickQuery();
    this.getplotname();
	this.getSearchClassIfication();
  },
  /*方法主体*/
  methods: {
    handleClickQuery:function(){
		this.dataList = [];
		var req = {};
		req.cmd = "queryEvaluate";
		req.start=(this.currentPage-1) * this.pagesize;
		req.count=this.pagesize;
		req.plot_id =this.article;    //所属项目
		req.status =this.status;      //会员状态
		req.selecttime=this.selecttime; //查询时间
		req.synthesize_params=this.tableDataName;   //综合查询
		req.father_id = this.type;
		this.$sknet.postJson(this.$skconf("brum.surl"), req, function(res) {
			this.dataList=res.datas.items;
			this.total=res.datas.totalCount;
			if(this.$route.params.currentPage != null && this.$route.params.currentPage != undefined){
				if(this.$route.params.currentPage > 1){
					this.currentPage = parseInt(this.$route.params.currentPage);
					this.$route.params.currentPage = 1;
				}			
			}
		}, null, this);
    },
    //查询所有项目的方法
    getplotname:function(){
      var self = this;
      self.options;
      var redata = {};
      redata.cmd = "getPmsName";
      redata.id=this.ids;
      this.$sknet.postJson(this.$skconf("met.surl"), redata, function(datas){
        this.optionsype=datas.datas.items;
      }, function(data) {
        self.$message(data.reason);
      }, self);
    },
	getSearchClassIfication:function(){
      var self = this;
      self.options;
      var redata = {};
      redata.cmd = "searchClassIfication";
      this.$sknet.postJson(this.$skconf("met.surl"), redata, function(datas){
        this.typeList = datas.datas.items;
      }, function(data) {
        self.$message(data.reason);
      }, self);
    },
    //重置方法
    reset:function(){
      this.article='';    //所属项目
      this.status='';      //会员状态
      this.selecttime=[]; //查询时间
      this.tableDataName='';   //综合查询
      this.handleClickQuery();
    },
// 根据数字返回文字
    formode:function(row,colum){
      const state={
        1:'有偿',
        2:'无偿',
      }
      return  state[row.mode];
    },
    forordertype:function(row,colum){
      const state={
        1:'等待抢单',
        2:'前台抢单',
        3:'后台分配',
      }
      return  state[row.order_type];
    },
    forstatus:function(row,colum){
      const state={
        3:'待评级',
        4:'已完成',
      }
      return  state[row.status];
    },
    forpriority:function(row,colum){
      const state={
        1:'一般',
        2:'紧急',
      }
      return  state[row.priority];
    },
    /*分页代码*/
    handleSizeChange: function (size) {
      this.pagesize = size;
    },
    handleCurrentChange: function(currentPage){
      this.currentPage = currentPage;
      this.handleClickQuery();
    },
    View:function(row){
		var req = {};
		req.father_id = row.id+"";
		req.order_type = row.order_type+"";
		req.currentPage = this.currentPage+"";		  
		req.article=this.article;    //所属项目
		req.status=this.status;      //会员状态
		req.selecttime=this.selecttime; //查询时间
		req.tableDataName=this.tableDataName;   //综合查询		
		req.type = this.type; //报修类型
		this.$router.push({name:'rms_evaluate_infolist',params:req});
	}
  },
  data() {
    let self = this;
    return{
      dataList:'',
      tableDataName:'',         //综合查询的字段
      total:0,          //总页数
      pagesize:10,        //每页数量
      currentPage:1,    //初始页
      article:'',     //所属项目选项
      optionsype:'',  //所属项目下拉
      status:'',      //报修状态选项
      selecttime:[],   //时间查询的字段
      statuss:[         //报修状态
        {
          value:'3',
          label:'待评级',
        },
        {
          value:'4',
          label:'已完成',
        },

      ],
	  typeList:[],
	  type:""
    }
  },
}
</script>